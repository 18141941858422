import RenderType from "./RenderType";
import $ from "jquery";
// font awesome
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

//icons
const r = require.context('../../images', false, /\.(png|jpe?g|svg)$/);
const images = r.keys().reduce((result, key) => {
  const clean_key = key.replace(/\.\//, "").split('.').slice(0, -1).join('.');
  result[clean_key] = r(key).default;
  return result;
}, {});

const renders = {
  [RenderType.SIMPLE]: renderSimple,
};

function renderSimple(results, where, resultContainerId, renderSettings, sizeSettings) {
  let resultContainer;
  if($("#"+resultContainerId).length > 0){
    resultContainer = $("#"+resultContainerId);
    resultContainer.empty();
    
  }else{
      resultContainer =$('<div id='+resultContainerId+'  class="box_search"/>')
      resultContainer[0].classList.add("search-as-you-type")
      resultContainer[0].classList.add(renderSettings.type);
      $(where).append(resultContainer);
  }

  const obj = {}
  for (const h of results.hits.hits) {
    const { title, host, permalink, language, categories, client} = h._source;
    const index = categories[0];
    if(!obj[index])
      obj[index] = {}
    obj[index][h._id] = {};
    obj[index][h._id]['title']= title;
    obj[index][h._id]['link'] = host + permalink;
    obj[index][h._id]['language'] = language;
  }

  for(const cat in obj){
      const results = obj[cat];
      createCategory(cat , results, resultContainer);
  }


  function createCategory(category, results, resultContainer){
    const section_search = $('<section class="section_search"/>');
    const article_search = $('<article class="article_search"/>');
    const header_search = $('<header class="header_search"/>');
    const ico = getIcon(category);
    const img_header_search = $('<img class="img_header_search"/>').attr("src", ico);
    const name_section_span = $('<span class="name_section"/>').text(category);
    header_search.append(img_header_search).append(name_section_span);
    const results_search = $('<section class="results_search">');
    for(const hit in results){
      const element_result_search = $('<article class="element_result_search">');
      //TODO: esto vale par los clientes PERO o el resto
      //const value_element = $('<span class="value_element">').text(result);
      //TOTO: tener en cuenta google tag manager en los enlaces
      //Añadimos identificador de origen a los enlaces de Solusoft, metiendolo con & o ? dependiendo del caso
      //TODO: añadir getQueryParams
      let search = ""
      if (window.location.search == "")
          search = "?sayt=" + $(".cajaBuscar")[0].value;
      else
          search = "&sayt=" + $(".cajaBuscar")[0].value;
      const value_element_link = $('<a class="value_element value_element_link">').text(results[hit].title).attr("href", results[hit].link + search);
      element_result_search.append(value_element_link);
      results_search.append(element_result_search);
    }
    
    article_search.append(header_search).append(results_search);
    section_search.append(article_search);
    resultContainer.append(section_search);
  }
} 

function setupSearchInputStyles(inputBox, resultContainerId) {
  // añadimos clase custom por si se desea añadir algún estilo a la caja
  $(inputBox).addClass("searchBox");
  // desactivamos autocomplete
  $(inputBox).attr("autocomplete", "off");
  const showResults = (event) => {
    event.stopPropagation();
    $('#' + resultContainerId).show();
    $('body').addClass("foregroundBody");
  };
  const hideResults = (event) => {
    $('#' + resultContainerId).hide();
    $('body').removeClass("foregroundBody");
  };
  // programamos comportamiento para cuando el usuario escribe o deja de hacerlo o cuando hace foco en la caja
  const onFocus = (event) => {
    if(inputBox.value != ""){
      showResults(event);
    }
  };
  const onTyping = (event) => {
    if(inputBox.value !== "") {
      showResults(event);
    } else {
      hideResults(event);
    }  
  };

  inputBox.addEventListener("focus", onFocus);
  inputBox.addEventListener("input", onTyping);
  $(document).on('click', (event) => { if (event.target.id !== inputBox.id) hideResults(event); });
}

function setupNavigation(resultContainerId){

  //TODO: QUITAR : OVER
  $(document).keydown(function (e) {
    var alinks = $("#" + resultContainerId).find('a');
    if (alinks.length > 0) {
      // get the index of the current focussed a 
      var keyIndex = alinks.index(alinks.filter(':focus'));
      alinks.eq(keyIndex).blur();
      if (e.keyCode == 40) { //Pulsa tecla Abajo
        e.preventDefault();
        // Busca el siguiente enlace a resaltar
        if (keyIndex < 0) {
          keyIndex = 0;
        } else if (keyIndex < alinks.length - 1) {
          keyIndex++;
        } else {
          keyIndex = 0; // esto ayuda a que sea ciclico
        }
        alinks.eq(keyIndex).focus();
      }
      else if (e.keyCode == 38) { //Pulsa tecla Arriba
        e.preventDefault();
        // Busca el anterior enlace a resaltar
        if (keyIndex > alinks.length - 1) {
          keyIndex = alinks.length - 1;
        } else if (keyIndex > 0) {
          keyIndex--;
        } else {
          keyIndex = alinks.length - 1; // esto ayuda a que sea ciclico
        }
        alinks.eq(keyIndex).focus();
      }
      const aLink = alinks[keyIndex];
      $(aLink).keydown(e => {
          if (e.keyCode == 13){
            window.location.href = aLink.href;
          }
      });
    }
  });

  //Si se mueve el raton se quita el focus
  $(document).mouseover(function(e){
    if(!resultContainerId.includes("#")){
      var alinks = $("#" + resultContainerId).find('a');
      if (alinks.length > 0) {
        // get the index of the current focussed a 
        var keyIndex = alinks.index(alinks.filter(':focus'));
        alinks.eq(keyIndex).blur();
      }
    }
  });
}

function getIcon(category) {
  const icoName = "ico_" + category;
  return images[icoName];
}

function render(results, where, resultContainerId, renderSettings, sizeSettings) {
  const { type, customRender } = renderSettings;
  //render
  let renderFunction; 
  if(type === RenderType.CUSTOM) renderFunction = customRender;
  else renderFunction = renders[type];
  renderFunction(results, where, resultContainerId, renderSettings, sizeSettings);
}


// return RenderManager class
export { render, setupSearchInputStyles, setupNavigation, getIcon }
