import SearchType from "./SearchType";
import AnalyticsManager from "./AnalyticsManager";
import $ from "jquery";

const _host = Symbol();
const _user = Symbol();
const _pass = Symbol();
const _userA = Symbol();
const _passA = Symbol();
const _index = Symbol();
const _searchSettings = Symbol();
const _analyticsManager = Symbol();

// TODO: poner aquí las queries reales con parámetros que luego sustituiremos, estas tendrán los boosts que nosotros consideremos
const queries = {
  [SearchType.SIMPLE]: { 
    query: {
      "_source": [
        "title",
        "categories",
        "host",
        "permalink",
        "language",
        "client",
        "sector"
      ],
      "query": {
        "function_score": {
          "query": {
            "bool": {
              "should": [
                {
                  "multi_match": {
                    "query": "",
                    "type": "bool_prefix",
                    "fields": [
                      /*"title._3gram^4",
                      "title^4",
                      "title._2gram^4",
                      "meta_title._3gram^3",
                      "meta_title^3",
                      "meta_title._2gram^3",
                      "short_description._3gram^2",
                      "short_description^2",
                      "short_description._2gram^2",
                      "meta_description._3gram",
                      "meta_description",
                      "meta_description._2gram"*/
                    ]
                  }
                },
                /*{
                    "match_bool_prefix": {
                        "tags": {
                            "query": "",
                            "boost": 3
                        }
                    }
                },
                {
                    "match_bool_prefix": {
                        "categories": {
                            "query": "",
                            "boost": 4
                        }
                    }
                }*/
              ],
              /*"filter":[
                { 
                    "term":{
                      "categories": "proyecto"
                    }
              }
              ], */
              "must_not": [
                {
                  "term": { "enabled": false }
                },
                {
                  "term": { "visibility": false }
                }
              ],
              "minimum_should_match": 1
            }
          },
          "functions": [
            /*{
                "filter": {
                    "match": {
                        "categories": "proyecto"
                    }
                },
                "weight": 4
            },
            {
                "filter": {
                    "match": {
                        "categories": "producto"
                    }
                },
                "weight": 3
            },
            {
                "filter": {
                    "match": {
                        "categories": "noticia"
                    }
                },
                "weight": 2
            },
            {
                "filter": {
                    "match": {
                        "categories": "evento"
                    }
                },
                "weight": 1
            },
            {
                "filter": {
                    "match": {
                        "categories": "video"
                    }
                },
                "weight": 1
            }*/
          ],
          "max_boost": 4,
          "score_mode": "max",
          "boost_mode": "multiply",
          "min_score": 2
        }
      }
    }
    , ranks: {
        title: 4, meta_title: 3, short_description: 2, meta_description: 1, tags: 3, 
        categories: {
          proyecto: 4, producto: 3, noticia: 2, video: 1, evento: 1, cliente: 1, otros: 1, categories: 4
        }
      }
    , filters: {}
  } //TODO: ranks es un objeto {title:3, category: { proyecto: 2}}, {size: 15, from: 4}
};

// return ElasticSearchManager class
export default class ElasticSearchManager {
  constructor(
    { host, user, pass, index }, // connection config
    { type, customQuery, customRanks, customFilters }, // search config [en un futuro también llegarán las opciones de pesos personalizados]   
    options = {}, // otras opciones
  ) {
    this[_host] = host;
    this[_user] = user;
    this[_pass] = pass;
    this[_index] = index;
    this[_searchSettings] = { type, customQuery, customRanks, customFilters };
    this[_analyticsManager] = new AnalyticsManager({ host, index }, {});
  }

  async connect() {
    let result;
    try {
      const b64auth = Buffer.from(`${this[_user]}:${this[_pass]}`).toString('base64');
      const auth = `Basic ${b64auth}`;
      var settings = {
        "url": this[_host],
        "method": "GET",
        "timeout": 0,
        "headers": {
          "Authorization": auth
        },
      };
      const response = await $.ajax(settings);
      result = response != null;
    } catch(err) {
      console.error(`Error connecting to ElasticSearch`, err);
    }
    return result;
  }

  //TODO: from habra que multiplicarlo from = (npage-1) * nSize
  async search(term, source, pid, uid, { nPage = 0, nSize = 15}) {
    let results;
    try {
      let elasticQuery = setupQuery(this[_searchSettings], source, term, { nPage, nSize });
      const b64auth = Buffer.from(`${this[_user]}:${this[_pass]}`).toString('base64');
      const auth = `Basic ${b64auth}`;
      var settings = {
        "url": `${this[_host]}/${this[_index]}/_search`,
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Content-Type": "application/json", 
          "Authorization": auth
        }, 
        "data": JSON.stringify(elasticQuery),
      };
      results = await $.ajax(settings);
      this[_analyticsManager].postEvent("busqueda", results, term, pid, uid, window.location.href, nSize, nPage);
    } catch(err) {
      console.error(`Error doing search`, err);
    }
    return results;


    function setupQuery(searchSettings, source, term, { nPage, nSize }) {
      const { type, customQuery, customRanks, customFilters } = searchSettings;
      let elasticQuery;
      if(type === SearchType.CUSTOM) {
        elasticQuery = customQuery;
      } else {
        const { query, ranks, filters } = queries[type];
        elasticQuery = query;
        const finalRanks = customRanks || ranks;
        const finalFilters = customFilters || filters;
        //Asignamos paginación
        elasticQuery.size = nSize;
        elasticQuery.from = (nPage)*nSize;
        //Asignamos los terminos de busqueda
        elasticQuery.query.function_score.query.bool.should[0].multi_match.query = term;
        //Asiganamos source si se ha pedido
        if(source == 0){
          elasticQuery._source[7] = "short_description";
          elasticQuery._source[8] = "description";
          elasticQuery._source[9] = "begin_date";
          elasticQuery._source[10] = "end_date";
        }
        //Asignamos los ranks
        applyRanks(elasticQuery, finalRanks);
        //Asignamos los filtros
        applyFilters(elasticQuery, finalFilters);
        // TODO: Sustituir configuración de paginación (si aplica)
        // Si viene paginación añadimos los parámetros de elastic de paginación, si no viene entonces elastic tiene que devolver todos los resultados
        // if(paginationConfig)
      }
      return elasticQuery;


      function applyFilters(elasticQuery, filters) {
        elasticQuery.query.function_score.query.bool.filter = [];
        let i = 0;
        for(const obj in filters){
          elasticQuery.query.function_score.query.bool.filter[i] = {};
          elasticQuery.query.function_score.query.bool.filter[i].term = {};
          elasticQuery.query.function_score.query.bool.filter[i].term[obj] = filters[obj];
          i++;
        }
      }

      function applyRanks(elasticQuery, ranks) {
        let i = 0
        let j = 0;
        for(const obj in ranks){
          if(obj == "categories"){
            for(const cat in ranks[obj]){
              if(cat == "categories"){
                elasticQuery.query.function_score.query.bool.should[2] = {};
                elasticQuery.query.function_score.query.bool.should[2].match_bool_prefix = {};
                elasticQuery.query.function_score.query.bool.should[2].match_bool_prefix[cat] = {};
                elasticQuery.query.function_score.query.bool.should[2].match_bool_prefix[cat].query = term;
                elasticQuery.query.function_score.query.bool.should[2].match_bool_prefix[cat].boost = ranks[obj][cat];
              }
              elasticQuery.query.function_score.functions[j]= {}
              elasticQuery.query.function_score.functions[j].weight = ranks[obj][cat] ;
              elasticQuery.query.function_score.functions[j].filter = {};
              elasticQuery.query.function_score.functions[j].filter.match = {};
              elasticQuery.query.function_score.functions[j].filter.match[obj] = cat;
              j++;
            }
          }else{
            if(obj == "tags"){
              elasticQuery.query.function_score.query.bool.should[1] = {};
              elasticQuery.query.function_score.query.bool.should[1].match_bool_prefix = {};
              elasticQuery.query.function_score.query.bool.should[1].match_bool_prefix[obj] = {};
              elasticQuery.query.function_score.query.bool.should[1].match_bool_prefix[obj].query = term;
              elasticQuery.query.function_score.query.bool.should[1].match_bool_prefix[obj].boost = ranks[obj];
            }
            elasticQuery.query.function_score.query.bool.should[0].multi_match.fields[i] = obj + "^"+ranks[obj]; i++;
            elasticQuery.query.function_score.query.bool.should[0].multi_match.fields[i] = obj + ".2gram^"+ranks[obj]; i++;
            elasticQuery.query.function_score.query.bool.should[0].multi_match.fields[i] = obj + ".3gram^"+ranks[obj]; i++;
          }
        }
      }
    }
  }
}
