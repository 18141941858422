// import dependencies
import { v4 as uuid } from 'uuid';
import { EventEmitter } from "events";
import ElasticSearchManager from "./bll/ElasticSearchManager";
import { render, setupSearchInputStyles, setupNavigation, getIcon } from "./bll/RenderManager";
import SearchType from "./bll/SearchType";
import RenderType from "./bll/RenderType";

// symbols
// properties
const _query = Symbol();
const _nPage = Symbol();
const _nSize = Symbol();
const _totalCount = Symbol();
const _pageCount = Symbol();
const _searchManager = Symbol();
// methods
const _setupInputBox = Symbol();
const _doSearch = Symbol();

// return SearchAsYouType class
class SearchAsYouType extends EventEmitter {

  // TODO: en algún momento probablemente ampliemos el constructor para que el usuario nos de su configuración de ranks (pesos)
  constructor(
    pid,
    resultContainerId,
    divID,
    source,
    elasticsearch, // elastic search settings
    {
      search: { 
        type: searchType = SearchType.SIMPLE, 
        customRanks, customFilters, customQuery,
        searchInputSelector,     
      } = {}, // search settings
      render: {
        type: renderType = RenderType.SIMPLE,
        customRender,
        setupNavigation,
      } = {}, // render settings
      // optional settings
    } = {},
  ) {
    super();
    this.pid = pid;
    this.uid = uuid();
    this.resultContainerId = resultContainerId;
    this.divID = divID,
    this.source = source,
    // add class

    // search
    this[_searchManager] = new ElasticSearchManager(
      elasticsearch,
      { type: searchType, customRanks, customFilters, customQuery },
      {},
    );
    // search
    this.searchSettings = { type: searchType, customRanks, customFilters, customQuery, searchInputSelector };
    // render
    this.renderSettings = { type: renderType, customRender, setupNavigation};
    // optional

    this.initialized = false;
  }

  //TODO: gets y sets para numero de pagina y numero de elementos de pagina
  // getters and setters
  get query() {
    return this[_query];
  }

  set query(query) {
    this[_query] = query;
    this[_doSearch]();
  }

  get nSize() {
    return this[_nSize];
  }

  set nSize(nSize) {
    this[_nSize] = nSize;
  }

  get nPage() {
    return this[_nPage];
  }

  set nPage(nPage) {
    this[_nPage] = nPage;
  }

  get totalCount() {
    return this[_totalCount];
  }

  set totalCount(totalCount) {
     this[_totalCount] = totalCount;
  }


  get pageCount() {
    return this[_pageCount];
  }

  set pageCount(pageCount) {
    this[_pageCount] = pageCount;
  }

  // initialize plugin
  async init() {
    // connect search manager
    await this[_searchManager].connect();
    // setup search as you type
    const inputId = this.searchSettings.searchInputSelector;
    if (inputId) this[_setupInputBox](inputId);

    //setup navigation
    if(this.renderSettings.setupNavigation)
      setupNavigation(this.resultContainerId);

    // set initialized to `true`
    this.initialized = true;
  }

  [_setupInputBox](inputId) {
    // setup input changes
    const inputBox = $(inputId)[0];
    setupSearchInputStyles(inputBox, this.resultContainerId);
    inputBox.addEventListener("input", () => this.query = inputBox.value);
  }

  async [_doSearch]() {
    if(!this.nSize) this.nSize = 15;
    if(this.nSize>51) this.nSize = 51;
    if(!this.nPage || this.nPage < 0) this.nPage = 0;
    const results = await this[_searchManager].search(this.query, this.source, this.pid, this.uid, { nPage: this.nPage, nSize:this.nSize});
    this[_totalCount] = results.hits.total.value;
    this[_pageCount] = results.hits.hits.length
    this.sizeSettings = { hits: this.nSize, totalCount: this.totalCount, page: this.nPage + 1, pageCount: this.pageCount };
    render(results, this.divID, this.resultContainerId, this.renderSettings, this.sizeSettings);
    this.emit("endSearch");
  }
}

// static properties
SearchAsYouType.SearchType = SearchType;
SearchAsYouType.RenderType = RenderType;
SearchAsYouType.getIcon = getIcon;

export default SearchAsYouType;