

import $ from "jquery";

const _host = Symbol();
const _index = Symbol();


// return AnalyticsManager class
export default class AnalyticsManager {
  constructor(
    { host, index }, // connection config
    options = {}, // otras opciones
  ) {
    this[_host] = host;
    this[_index] = index;
  }

  async connect() {
    let result;
    try {
      const b64auth = Buffer.from(`analytics_user:KtiyE9CmyI+3I5mL`).toString('base64');
      const auth = `Basic ${b64auth}`;
      var settings = {
        "url": this[_host],
        "method": "GET",
        "timeout": 0,
        "headers": {
          "Authorization": auth
        },
      };
      const response = await $.ajax(settings);
      result = response != null;
    } catch(err) {
      console.error(`Error connecting to ElasticSearch`, err);
    }
    return result;
  }

  //TODO: from habra que multiplicarlo from = (npage-1) * nSize
  async postEvent(name, resultsQuery, term, pid, uid, origin, size, page) {
    let results;
    try {
      const b64auth = Buffer.from(`analytics_user:KtiyE9CmyI+3I5mL`).toString('base64');
      const auth = `Basic ${b64auth}`;
      let event = setUpEvent(name, resultsQuery, term, pid, uid, origin, size, page);
        var settings = {
            "url": `${this[_host]}/plugin_analytics/_doc`,
            "method": "POST",
            "timeout": 0,
            "headers": {
            "Content-Type": "application/json", 
            "Authorization": auth
            }, 
            "data": JSON.stringify(event),
        };
        results = await $.ajax(settings);
      console.log(results);
    } catch(err) {
      console.error(`Error doing search`, err);
    }
    return results;
  

  
    function setUpEvent(name, results, term, pid, uid, origin, size, page){
        let event = {};
        event.name = name;
        event.pid = pid;
        event.uid = uid;
        event["@timestamp"] = new Date().toISOString();
        event.query = term === '' ? "" : term;
        let dividedURL = origin.split("?");
        event.originURL =  dividedURL[0];
        event.queryParams = dividedURL.length === 1 ? "" : "?" + dividedURL[1];
        event.fullResultsSize = results.hits.total.value;
        event.pageResultsSize = size;
        event.actualPage = page;
        event.took = results.took;
        return event;
    }
  }
}
